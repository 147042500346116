






































































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      loaded: false,
      dialog: false,
      item: [
        {
          arrivalDateClose: false,
          leaveDateClose: false,
        },
      ],
      rooms: [],
    });

    const model = reactive<{
      data: any;
    }>({
      data: {
        name: null,
        representatives: [],
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`company/${root.$route.params.cid}`)
        .then(({ data: { company } }) => {
          model.data.name = company.name;
          model.data.representatives = company.representatives || null;

          company.representatives.forEach(() => {
            state.item.push({
              arrivalDateClose: false,
              leaveDateClose: false,
            });
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        representatives: model.data.representatives || null,
      };

      state.loading = true;

      axiosInstance
        .put(`company/${root.$route.params.cid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.companyExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const addItem = () => {
      model.data.representatives.push({
        firstName: null,
        lastName: null,
        roommate: null,
        arrival: null,
        leave: null,
      });
      state.item.push({
        arrivalDateClose: false,
        leaveDateClose: false,
      });
    };

    const removeItem = (index: number) => {
      model.data.representatives.splice(index, 1);
    };

    return { state, model, onSubmit, getErrorMessage, addItem, removeItem };
  },
});
